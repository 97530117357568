* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.app {
  height: 100vh;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #e6e3dc;
  }

  &::-webkit-scrollbar-thumb {
    background: #949494;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

html {
  font-size: 20px;
  color: black;
}

* {
  font-family: "Roboto", sans-serif;
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RobotoBold";
  src: url("../public/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RobotoLight";
  src: url("../public/fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PoppinsBold";
  src: url("../public/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PoppinsLight";
  src: url("../public/fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-VariableFont_opsz,wght.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/static/Inter_18pt-Black.ttf")
    format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/static/Inter_18pt-ExtraBold.ttf")
    format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/static/Inter_18pt-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/static/Inter_18pt-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/static/Inter_18pt-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/static/Inter_18pt-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/static/Inter_18pt-Light.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/static/Inter_18pt-ExtraLight.ttf")
    format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/static/Inter_18pt-Thin.ttf")
    format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "WorkSans";
  src: url("../public/fonts/Work_Sans/WorkSans-VariableFont_wght.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
